import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { Button } from 'antd';
import { AUTHORITIES } from 'common/constants';
import CountryPicker from 'common/layouts/Header/CountryPicker';
import { alertErrorMessage } from 'common/utils';
import { useTranslation } from 'react-i18next';
import './Organization.scss';

const Organization = () => {
    const { instance } = useMsal();
    const { t } = useTranslation();

    const handleOrganizationClick = async (authority = AUTHORITIES.MULTI_TENANT) => {
        localStorage.clear();
        const redirectUri = '/';

        try {
            await instance.loginRedirect({ authority, redirectUri });
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    return (
        <div className="organization bg-inherit">
            <div className="content">
                <div className="country-picker fixed top-4 right-8 z-10">
                    <CountryPicker />
                </div>
                <div className="welcome absolute xs:top-[10vh] md:top-[25vh] w-full px-2">
                    <h1 className="xs:text-xl sm:text-2xl md:text-3xl text-white text-center">
                        {t('organization.chooseOrganization')}
                    </h1>
                </div>
                <div className="option flex xs:flex-col md:flex-row xs:space-y-4 md:space-y-0 xs:space-x-0 md:space-x-4 justify-center items-center h-full">
                    <Button
                        className="xs:px-2 xs:py-4 sm:px-10 sm:py-4 font-semibold text-sky-900 focus-within:text-sky-900 hover:text-sky-700
                            xs:w-64 xs:h-32 sm:w-80 sm:h-32 md:w-80 md:h-32 lg:w-96 lg:h-48
                            bg-white rounded-lg border-white border-solid cursor-pointer transition 
                            flex flex-col justify-center items-center align-middle space-y-4"
                        onClick={() => handleOrganizationClick(AUTHORITIES.SINGLE_TENANT)}
                    >
                        <HomeOutlined className="xs:text-4xl sm:text-5xl" />
                        <span className="xs:text-sm sm:text-lg lg:text-xl">
                            {t('organization.defaultOrganization')}
                        </span>
                    </Button>
                    <Button
                        className="xs:px-2 xs:py-4 sm:px-10 sm:py-4 font-semibold text-sky-900 focus-within:text-sky-900 hover:text-sky-700
                            xs:w-64 xs:h-32 sm:w-80 sm:h-32 md:w-80 md:h-32 lg:w-96 lg:h-48
                            bg-white rounded-lg border-white border-solid cursor-pointer transition 
                            flex flex-col justify-center items-center align-middle space-y-4"
                        onClick={() => handleOrganizationClick(AUTHORITIES.MULTI_TENANT)}
                    >
                        <UserOutlined className="xs:text-4xl sm:text-5xl" />
                        <span className="xs:text-sm sm:text-lg lg:text-xl">
                            {t('organization.yourOrganization')}
                        </span>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Organization;
